<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 24 24"
        fill="none"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
    >
        <g>
            <g id="Layer_1">
                <path
                    d="M7.5,3h-2.2c-1.2,0-2.2,1-2.2,2.2v13.5c0,1.2,1,2.2,2.2,2.2h2.2M12,16.5l-4.5-4.5M7.5,12l4.5-4.5M7.5,12h13.5"
                />
            </g>
        </g>
    </svg>
</template>

<script setup lang="ts">
interface Props {
    color?: string;
}

const props = defineProps<Props>();

const color = props.color || "currentColor";
</script>

<style scoped lang="scss"></style>
