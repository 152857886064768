<template>
    <div
        :class="['icon-with-text flex gap-2', iconPositionClass, groupClasses]"
        role="group"
    >
        <slot name="icon"></slot>
        <slot></slot>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
    variant?: string;
    iconPosition?: "top" | "left";
}>();

const iconPositionClass = computed(() => {
    return props.iconPosition === "top"
        ? "flex-col justify-center text-center items-center"
        : "flex-row text-left items-center";
});

const variant = props.variant ?? "";

const groupClasses = computed(() => {
    return {
        "flex-1": variant === "autowidth",
        "flex-auto max-w-max": variant === "room",
        "flex-auto max-w-24 md:flex-1 md:max-w-full": variant === "experience",
    };
});
</script>

<style scoped lang="scss"></style>
