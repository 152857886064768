import { useUserStore } from "@/stores/auth/user";
import { baseGate } from "@m-media/vue3-gate-keeper";

export default class extends baseGate {
    async handle() {
        const userStore = useUserStore();

        if (userStore.state.isAuthenticated) {
            return {
                path: "index",
                setRedirectToIntended: false,
            };
        }
    }
}
