<template>
    <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26.57 17.33"
        class="size-6 fill-primary stroke-primary"
        aria-labelledby="guestIconTitle guestIconDesc"
        role="img"
    >
        <title id="guestIconTitle">Guest Icon</title>
        <desc id="guestIconDesc">Guest Icon</desc>
        <path
            stroke-width="0"
            d="M.07 17.17c.08.1.2.16.33.16h16.7c.13 0 .25-.06.33-.16s.1-.23.07-.36c-.1-.39-.24-.77-.4-1.13h9.06c.13 0 .25-.06.33-.16s.1-.23.07-.36c-1.01-3.82-4.62-6.49-8.77-6.49-2.26 0-4.36.81-5.97 2.17-.97-.33-2.01-.52-3.09-.52-4.12 0-7.71 2.67-8.72 6.49-.03.12 0 .25.07.36H.07Zm.89-.67c1.09-3.18 4.21-5.36 7.77-5.36.83 0 1.64.12 2.41.34a8.454 8.454 0 0 0-2.07 3.68c-.03.12 0 .25.07.36s.2.16.33.16h6.74c.13.27.25.54.35.82H.96Zm16.82-7c3.59 0 6.73 2.18 7.82 5.36H10.01a7.68 7.68 0 0 1 1.98-3.07c.11-.1.23-.2.34-.3.12-.1.24-.19.36-.29a8.42 8.42 0 0 1 5.09-1.7Z"
        />
        <path
            stroke-width="0"
            d="M12.9 5.79c0-2.29-1.86-4.15-4.15-4.15S4.6 3.5 4.6 5.79s1.86 4.15 4.15 4.15 4.15-1.86 4.15-4.15Zm-.83 0c0 1.83-1.49 3.32-3.32 3.32S5.43 7.62 5.43 5.79s1.49-3.32 3.32-3.32 3.32 1.49 3.32 3.32ZM21.96 4.15C21.96 1.86 20.1 0 17.81 0s-4.15 1.86-4.15 4.15 1.86 4.15 4.15 4.15 4.15-1.86 4.15-4.15Zm-.83 0c0 1.83-1.49 3.32-3.32 3.32s-3.32-1.49-3.32-3.32S15.98.83 17.81.83s3.32 1.49 3.32 3.32Z"
        />
    </svg>
</template>

<script setup lang="ts">
interface Props {
    color?: string;
}

const props = defineProps<Props>();

const color = props.color || "currentColor";
</script>

<style scoped lang="scss"></style>
