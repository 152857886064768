import { baseGate } from "@m-media/vue3-gate-keeper";
import { useBookingStore } from "@/stores/booking/booking";

/** A middleware that checks if the user is authenticated */
export default class extends baseGate {
    async handle() {
        const bookingStore = useBookingStore();

        if(bookingStore.state.booking?.experience !== this.options.gateOptions?.experience) {
            return {
                path: "NotFound",
                setRedirectToIntended: false,
            };
        } 
    }
}
