<template>
    <svg
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18.8 18.99"
        aria-labelledby="cartIconTitle cartIconDesc"
        role="img"
        class="size-6"
    >
        <title id="cartIconTitle">Cart Icon</title>
        <desc id="cartIconDesc">Cart Icon</desc>
        <path
            d="M18.41 7.73c-.38-.48-.98-.76-1.66-.76h-1.7L12.71.34a.506.506 0 0 0-.64-.31c-.26.09-.4.38-.31.64l2.22 6.3H4.85L7.07.67a.498.498 0 0 0-.31-.64.506.506 0 0 0-.64.31L3.78 6.97h-1.7c-.68 0-1.29.28-1.67.76s-.5 1.14-.33 1.8l1.79 7.14c.32 1.28 1.65 2.32 2.97 2.32h9.13c1.32 0 2.65-1.04 2.97-2.32l1.79-7.14c.17-.66.04-1.31-.33-1.8Zm-.64 1.56-1.79 7.14c-.21.85-1.13 1.56-2 1.56H4.85c-.87 0-1.79-.71-2-1.56L1.06 9.29c-.09-.36-.04-.7.15-.94s.5-.38.88-.38h14.65c.37 0 .69.13.88.38.19.24.25.58.15.94Z"
            :style="`fill: ${color}; stroke-width: 0`"
            data-name="Layer 1"
        />
    </svg>
</template>

<script setup lang="ts">
interface Props {
    color?: string;
}

const props = defineProps<Props>();

const color = props.color || "currentColor";
</script>

<style scoped lang="scss"></style>
