<template>
    <div
        :class="[
            'notice top-0 z-40 bg-blush-900 text-primary p-4 w-full transition-all',
            { sticky: isSticky },
        ]"
        role="alert"
        ref="notice"
        aria-live="assertive"
        v-if="messageNotifications.length > 0"
    >
        <Vue3Marquee v-if="scrolling" :speed="computedSpeed" aria-hidden="true">
            <span v-html="currentMessage"></span>
        </Vue3Marquee>
        <div v-else v-html="currentMessage"></div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed } from "vue";
import { debounce } from "lodash";
import { useSystemStore } from "@/stores/system/system";
import { useBookingStore } from "@/stores/booking/booking";

interface Props {
    scrolling: boolean;
    speed: number;
}

const systemStore = useSystemStore();
const bookingStore = useBookingStore();
const props = defineProps<Props>();

const isSticky = ref(false);
const notice = ref<HTMLElement | null>(null);
const currentIndex = ref(0);
const currentMessage = ref<string>("");

const computedSpeed = computed(() => props.speed ?? 30);

const messageNotifications = computed(() => {
    return systemStore.state.notifications.filter((notification) => {
        return (
            notification.type === "message" &&
            (Number(notification.venue_id) === 0 ||
                Number(notification.venue_id) ===
                    bookingStore.state.booking.venue_id) &&
            (notification.stay_type === "all" ||
                notification.stay_type ===
                    bookingStore.state.booking.experience)
        );
    });
});

const updateMessage = () => {
    if (messageNotifications.value.length > 0) {
        currentMessage.value =
            messageNotifications.value[currentIndex.value].message;
        currentIndex.value =
            (currentIndex.value + 1) % messageNotifications.value.length;
    }
};

const handleScroll = debounce(() => {
    if (notice.value) {
        const offsetTop = notice.value.offsetTop;
        isSticky.value = window.scrollY > offsetTop;
    }
}, 100);

let messageInterval: ReturnType<typeof setInterval>;

onMounted(() => {
    updateMessage();
    window.addEventListener("scroll", handleScroll);

    messageInterval = setInterval(updateMessage, 5000);
});

onUnmounted(() => {
    window.removeEventListener("scroll", handleScroll);
    clearInterval(messageInterval);
});
</script>

<style scoped lang="scss">
.notice {
    position: sticky;
}

.sticky {
    @apply shadow-lg;
}
</style>
