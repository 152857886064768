<template>
    <svg
        :class="size"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="8" cy="8" r="8" :class="color" />
        <path
            d="M4 8.52941L6.33333 11L12 5"
            stroke="white"
            stroke-linecap="round"
        />
    </svg>
</template>

<script setup lang="ts">
interface Props {
    color?: string;
    size?: string;
}

const props = defineProps<Props>();

const color = props.color || "fill-primary";
const size = props.size || "size-6";
</script>

<style scoped lang="scss"></style>
