import { onEvent, offEvent } from "@/helpers/eventEmitter";
import type { DataLayerObject } from "@gtm-support/vue-gtm";
import { ref } from "vue";
import { useGtm } from "@gtm-support/vue-gtm";
import api from "@/api/axios";
import { useUserStore } from "@/stores/auth/user";
import { useSystemStore } from "@/stores/system/system";

interface AnalyticsEvent {
    type: string;
    [key: string]: any;
}

const setupGlobalAnalyticsListener = () => {
    const handleAnalyticsEventRef = ref<
        ((event: AnalyticsEvent) => void) | null
    >(null);

    const handleAnalyticsEvent = (event: AnalyticsEvent) => {
        console.log("Global Analytics Event:", event);

        const gtm = useGtm();
        const userStore = useUserStore();
        const systemStore = useSystemStore();

        if (gtm) {
            gtm.trackEvent({
                event: event.type,
                ...event,
            });
        }
    };

    handleAnalyticsEventRef.value = handleAnalyticsEvent;

    onEvent("analytics-event", handleAnalyticsEvent);

    return () => {
        if (handleAnalyticsEventRef.value) {
            offEvent("analytics-event", handleAnalyticsEventRef.value);
        }
    };
};

export default setupGlobalAnalyticsListener;
