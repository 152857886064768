import { createApp } from "vue";
import { createPinia } from "pinia";
import { gatePlugin } from "@m-media/vue3-gate-keeper";
import { createHead } from "@unhead/vue";
import gates from "./router/gates";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import setupGlobalAnalyticsListener from "@/services/analyticsService";
import { createGtm } from "@gtm-support/vue-gtm";

import i18n from "@/plugins/i18n";

import App from "./App.vue";
import router from "./router";
import "./assets/base.scss";
import "swiper/swiper-bundle.css";

import { setupCalendar } from "v-calendar";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import Vue3Marquee from "vue3-marquee";

import { createNotivue } from "notivue";
import "notivue/notification.css";
import "notivue/animations.css";
import "notivue/notification-progress.css";
import * as Sentry from "@sentry/vue";

const notivue = createNotivue({
    position: "top-right",
    limit: Infinity,
    enqueue: true,
    avoidDuplicates: true,
    notifications: {
        global: {
            duration: 8000,
        },
    },
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

const head = createHead();

const gtmId = import.meta.env.VITE_GTM_ID;

const gtm = createGtm({
    id: gtmId,
    vueRouter: router,
});

const app = createApp(App)
    .use(pinia)
    .use(router)
    .use(head)
    .use(gatePlugin, { gateInstances: gates }, router)
    .use(setupCalendar, {})
    .use(notivue)
    .use(Vue3Marquee)
    .use(i18n)
    .use(gtm);

app.component("VueDatePicker", VueDatePicker);

Sentry.init({
    app,
    dsn: "https://141ab65bf196ae354c774285094849cd@o4505833138290688.ingest.us.sentry.io/4508008091025408",
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
            networkDetailAllowUrls: [/^https:\/\/api\.baronseden\.com\/api\//],
            networkRequestHeaders: ["X-Custom-Header"],
            networkResponseHeaders: ["X-Custom-Header"],
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
        "localhost",
        /^https:\/\/api.baronseden\.com\/api/,
        /^https:\/\/booking-api\.test\/api/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

setupGlobalAnalyticsListener();

app.mount("#app");
