<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 22 22"
        stroke-width="1.5"
        :stroke="color"
        class="size-8"
        aria-labelledby="profileIconTitle profileIconDesc"
        role="img"
    >
        <title id="profileIconTitle">Profile Icon</title>
        <desc id="profileIconDesc">Profile Icon</desc>
        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        />
    </svg>
</template>

<script setup lang="ts">
interface Props {
    color?: string;
}

const props = defineProps<Props>();

const color = props.color || "currentColor";
</script>

<style scoped lang="scss">
svg {
    stroke: var(--color-primary);
}
</style>
