import { baseGate } from "@m-media/vue3-gate-keeper";
import { useBookingStore } from "@/stores/booking/booking";
import { useRouter } from "vue-router";

/** A middleware that checks if the user is authenticated */
export default class extends baseGate {
    async handle() {
        const booking = useBookingStore();
        const router = useRouter();

        if (booking.state.booking.dining_items.length > 0) {
            router.push({ name: "Checkout" });
        }
    }
}
