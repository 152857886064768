import { createI18n } from "vue-i18n";
import axios from "axios";
import en from "@/locales/en";

const apiUrl = import.meta.env.VITE_BOOKING_API_URL;

const i18n = createI18n({
    locale: "en",
    messages: {
        en,
    },
});

async function loadLocaleMessages(locale: string) {
    try {
        const response = await axios.get(`${apiUrl}/locales/${locale}.json`, {
            headers: { "Access-Control-Allow-Origin": "*" },
        });

        const messages = response.data;

        i18n.global.setLocaleMessage(locale, messages);
        i18n.global.locale = locale as "en";
    } catch (error) {
        console.error("Error loading locale messages from server:", error);
    }
}

loadLocaleMessages("en");

export default i18n;
