<template>
    <div class="md:relative w-full" ref="popoverRef">
        <div
            :class="popoverClasses"
            @click="togglePopover"
            role="button"
            aria-haspopup="true"
            :aria-expanded="isOpen"
        >
            <slot name="icon" />
            <span :class="labelClasses" v-if="label">{{ label }}</span>
            <DownArrowIcon v-if="!hideIcon" />
        </div>
        <Transition name="fade" appear>
            <div
                v-if="isOpen"
                class="absolute left-0 right-0 mt-2 flex flex-col p-4 gap-2 border border-stone-100 bg-ivory-900 shadow-lg z-40 w-[90vw] max-h-screen overflow-y-auto max-w-48 min-w-48 mx-auto | md:w-auto md:left-auto md:max-w-60"
                role="menu"
                aria-labelledby="popover-label"
                tabindex="-1"
                ref="popoverContentRef"
            >
                <slot></slot>
            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, nextTick, computed } from "vue";
import DownArrowIcon from "@/components/icons/DownArrowIcon.vue";

interface Props {
    label?: string;
    hideIcon?: boolean;
    labelStyle?: "subheading";
    variant?: "basic" | "headermenu" | "giftcard" | "sortby";
}

const props = defineProps<Props>();
const isOpen = ref(false);
const popoverRef = ref<HTMLElement | null>(null);
const popoverContentRef = ref<HTMLElement | null>(null);
const variant = props.variant || "basic";
const labelVariant = props.labelStyle || "basic";

const popoverClasses = computed(() => {
    const baseClass = "flex items-center gap-1 cursor-pointer";

    const variantClasses: Record<string, string> = {
        basic: `ml-auto z-50`,
        headermenu: `py-10 top-4 right-0 z-50`,
        sortby: `top-4 right-0 z-50`,
        giftcard: `ml-auto z-50`,
    };

    return `${baseClass} ${variantClasses[variant] || ""}`;
});

const labelClasses = computed(() => {
    const variantClasses: Record<string, string> = {
        basic: `font-subheading text-xs uppercase font-semibold`,
        headermenu: `font-subheading text-xs uppercase`,
        sortby: `font-subheading text-xs uppercase`,
        giftcard: `font-subheading text-xs uppercase`,
    };

    return `${variantClasses[labelVariant] || ""}`;
});

const togglePopover = () => {
    isOpen.value = !isOpen.value;
    if (isOpen.value) {
        nextTick(() => {
            adjustPopoverPosition();
            popoverContentRef.value?.focus();
        });
    }
};

const closePopover = () => {
    isOpen.value = false;
};

const adjustPopoverPosition = () => {
    const popoverContent = popoverContentRef.value;
    if (popoverContent) {
        const popoverRect = popoverContent.getBoundingClientRect();
        const viewportWidth = window.innerWidth;

        if (popoverRect.right > viewportWidth) {
            popoverContent.style.left = "auto";
            popoverContent.style.right = "0";
        } else {
            popoverContent.style.left = "";
            popoverContent.style.right = "";
        }
    }
};

const handleClickOutside = async (event: MouseEvent) => {
    await nextTick();

    if (popoverRef.value && !popoverRef.value.contains(event.target as Node)) {
        closePopover();
    }
};

const handleEscape = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
        closePopover();
    }
};

onMounted(() => {
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("keydown", handleEscape);
});

onBeforeUnmount(() => {
    document.removeEventListener("click", handleClickOutside);
    document.removeEventListener("keydown", handleEscape);
});

defineExpose({
    togglePopover,
});
</script>

<style scoped lang="scss">
:deep() {
    svg {
        @apply w-5;
    }
}
</style>
