import { ref } from 'vue';

export const eventEmitter = ref<Record<string, Function[]>>({});

export const emitEvent = (event: string, ...args: any[]) => {
    const callbacks = eventEmitter.value[event];
    if (callbacks) {
        callbacks.forEach(callback => callback(...args));
    }
};

export const onEvent = (event: string, callback: Function) => {
    if (!eventEmitter.value[event]) {
        eventEmitter.value[event] = [];
    }
    eventEmitter.value[event].push(callback);
};

export const offEvent = (event: string, callback: Function) => {
    const callbacks = eventEmitter.value[event];
    if (callbacks) {
        const index = callbacks.indexOf(callback);
        if (index !== -1) {
            callbacks.splice(index, 1);
        }
    }
};
