<template>
    <div class="cart-item p-6 flex flex-col gap-6 bg-cart">
        <div
            class="flex gap-3 justify-between items-center pb-2 border-b border-stone-100"
        >
            <h2
                class="font-subheading font-semibold uppercase text-sm line-clamp-1"
                role="heading"
                aria-level="2"
            >
                {{
                    truncateText(
                        item.treatment.custom_title ?? item.treatment.title,
                        25
                    )
                }}
            </h2>
            <p
                class="font-subheading text-[16px] font-semibold"
                aria-live="polite"
            >
                £{{ item.total }}
            </p>
        </div>
        <div class="flex justify-between items-center mb-4 text-sm mt-4">
            <IconWithText
                iconPosition="top"
                role="listitem"
                aria-label="mins"
                variant="autowidth"
            >
                <template #icon>
                    <TimerIcon />
                </template>
                <span class="font-sans text-xxs font-light line-clamp-1">
                    {{ item.treatment.service_time }}
                    {{ $t("common.minutes") }}
                </span>
            </IconWithText>
            <IconWithText
                iconPosition="top"
                role="listitem"
                aria-label="day"
                variant="autowidth"
            >
                <template #icon>
                    <ClockIcon />
                </template>
                <span class="font-sans text-xxs font-light line-clamp-1">{{
                    formatTime(item.start_time)
                }}</span>
            </IconWithText>
        </div>

        <DynamicButton
            @click="removeTreatment(item)"
            variant="text"
            size="none"
            additionalClasses="!text-danger-900 text-xxs"
            aria-label="Remove treatment"
        >
            {{ $t("common.removeTreatment") }}
        </DynamicButton>
    </div>

    <PopupModel
        :visible="confirmationModalVisible"
        :title="$t('reviewBooking.confirmTitle')"
        @close="confirmationModalVisible = false"
    >
        {{ $t("reviewBooking.confirmMessage") }}
        <template #buttons>
            <DynamicButton
                variant="solid"
                @click="confirmRemoval(true)"
                additionalClasses="mt-4"
                aria-label="Close guest selection modal"
            >
                {{ $t("common.remove") }}
            </DynamicButton>
            <DynamicButton
                variant="outline"
                @click="confirmRemoval(false)"
                aria-label="Close guest selection modal"
            >
                {{ $t("common.cancel") }}
            </DynamicButton>
        </template>
    </PopupModel>

    <LoadingLogo
        v-if="isLoading"
        :loading="isLoading"
        :message="$t('common.updatingBooking')"
    />
</template>

<script setup lang="ts">
import { ref } from "vue";
import IconWithText from "@/components/global/IconWithText.vue";
import DynamicButton from "@/components/forms/DynamicButton.vue";
import ClockIcon from "@/components/icons/ClockIcon.vue";
import TimerIcon from "@/components/icons/TimerIcon.vue";
import type { BookingSpaItem } from "@/types";
import { useSpaStore } from "@/stores/spa/spa";
import PopupModel from "@/components/global/PopupModel.vue";
import { useBookingStepsStore } from "@/stores/booking/bookingSteps";
import { useBookingStore } from "@/stores/booking/booking";
import LoadingLogo from "@/components/global/LoadingLogo.vue";

interface Props {
    title: string;
    item: BookingSpaItem;
}

const props = defineProps<Props>();

const bookingStore = useBookingStore();
const spaStore = useSpaStore();
const bookingStepsStore = useBookingStepsStore();
const confirmationModalVisible = ref(false);
const resolveConfirmation = ref<((isConfirmed: boolean) => void) | null>(null);
const isLoading = ref(false);

const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
    }
    return text;
};

const confirmRemoval = (isConfirmed: boolean) => {
    confirmationModalVisible.value = false;
    if (resolveConfirmation.value) {
        resolveConfirmation.value(isConfirmed);
    }
};

const showConfirmationModal = () => {
    confirmationModalVisible.value = true;

    return new Promise((resolve) => {
        resolveConfirmation.value = resolve;
    });
};

const removeTreatment = async (treatment: BookingSpaItem) => {
    try {
        const isConfirmed = await showConfirmationModal();

        if (!isConfirmed) {
            return;
        }

        isLoading.value = true;

        const response = await spaStore.removeTreatment(
            treatment,
            null,
            null,
            treatment.treatment_id,
            treatment.spa_folio_id,
            treatment.spa_folio_item_id
        );

        if (response && treatment.is_inclusive) {
            isLoading.value = false;
            await bookingStepsStore.resetToStep(2);
        } else {
            if (
                bookingStore.state.booking.experience === "treatments" &&
                bookingStore.state.booking.treatment_items.length === 0
            ) {
                await bookingStepsStore.resetToStep(1);
            }
        }
    } catch (error) {
        console.error("Error during treatment removal", error);

        throw error;
    }
};

const formatTime = (dateString: string): string => {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const formattedHours = hours % 12 || 12;
    const period = hours >= 12 ? "PM" : "AM";

    return `${formattedHours}:${minutes} ${period}`;
};
</script>
