import { baseGate } from "@m-media/vue3-gate-keeper";
import { useBookingStore } from "@/stores/booking/booking";
import { useRouter } from "vue-router";
import { useBookingStepsStore } from "@/stores/booking/bookingSteps";

/** A middleware that checks if the user is authenticated */
export default class extends baseGate {
    async handle() {
        const booking = useBookingStore();
        const bookingStepsStore = useBookingStepsStore();

        if (booking.state.booking.cart_items === 0) {
            await bookingStepsStore.resetToStep(1);
        }
    }

}
