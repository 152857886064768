<template>
    <div
        class="room-selector relative flex flex-col gap-2 flex-auto"
        ref="roomSelectorRef"
    >
        <label
            for="room-selection"
            class="room-selector-heading font-subheading font-extrabold text-black-600 text-sm"
        >
            {{ $t("roomSelection.title") }}
        </label>
        <div class="relative">
            <input
                type="text"
                name="room-selection"
                id="room-selection"
                :value="summary"
                :placeholder="$t('roomSelection.placeholder')"
                @click="popoverVisible = !popoverVisible"
                :class="`room-selector-overview font-body w-full bg-selectBG border border-stone-900 px-4 py-1 leading-10 cursor-pointer text-base md:text-[14px] ${selectWrapperClasses}`"
                readonly
                aria-readonly="true"
                aria-haspopup="dialog"
                :aria-expanded="popoverVisible"
            />
            <div
                class="absolute top-1/2 right-4 -translate-y-1/2 pointer-events-none"
            >
                <div v-if="hasRoomOrGuestChanges">
                    <CompleteIcon size="size-4" />
                </div>
                <div v-else>
                    <DownArrowIcon />
                </div>
            </div>
        </div>
        <transition name="fade" appear>
            <div
                class="overlay md:hidden"
                v-if="popoverVisible"
                aria-hidden="true"
                @click="closeRoomSelector"
            ></div>
        </transition>
        <transition name="fade-slide" appear>
            <div
                v-if="popoverVisible"
                class="room-selector-popup fixed bottom-0 left-0 w-full z-[61] | md:absolute md:top-full"
                ref="popupRef"
            >
                <div
                    class="room-selector-popup-container border border-stone-900 bg-ivory-800 text-textcolor shadow-lg"
                >
                    <div
                        class="touch-control w-24 h-1 bg-black-900/10 rounded-md mx-auto mt-6 mb-10 md:hidden"
                        @click="closeRoomSelector"
                        ref="touchControlRef"
                    ></div>
                    <div class="room-selector-popup-inner p-6">
                        <div
                            class="room-selector-popup-headings flex justify-between mb-6"
                        >
                            <h2
                                class="room-selector-popup-inner-heading flex-1 text-sm font-subheading uppercase font-semibold"
                            >
                                {{ $t("common.rooms") }}
                            </h2>
                            <h2
                                class="room-selector-popup-inner-heading w-24 text-sm font-subheading uppercase font-semibold"
                            >
                                {{ $t("common.adults") }}
                            </h2>
                        </div>
                        <div
                            class="room-selector-popup-inner-rooms flex flex-col space-y-4"
                        >
                            <div
                                v-for="(room, index) in rooms"
                                :key="index"
                                class="room-selector-popup-inner-room flex justify-between items-center"
                            >
                                <span
                                    class="room-selector-popup-item flex-1 text-sm font-subheading flex flex-col items-start font-light"
                                    >{{ $t("common.room") }} {{ index + 1 }}
                                    <DynamicButton
                                        v-if="index > 0"
                                        variant="text"
                                        size="none"
                                        additionalClasses="room-selector-popup-item-remove p-0 !text-danger-900 text-xxs font-subheading"
                                        @click="removeRoom(index)"
                                        aria-label="Remove room"
                                    >
                                        {{ $t("common.remove") }}
                                    </DynamicButton>
                                </span>
                                <div
                                    class="flex items-center w-24 justify-between"
                                >
                                    <button
                                        @click="decrement(index)"
                                        :disabled="room.adults === 1"
                                        class="room-selector-popup-decrease w-8 h-8 bg-primary text-ivory-900 flex items-center justify-center disabled:opacity-50 rounded-sm"
                                        aria-label="Decrease adults"
                                        :aria-disabled="room.adults === 1"
                                    >
                                        <MinusIcon />
                                    </button>
                                    <span
                                        class="room-selector-popup-item-adults text-xs font-subheading font-light"
                                        >{{ room.adults }}</span
                                    >
                                    <button
                                        @click="increment(index)"
                                        :disabled="
                                            room.adults >= 4 || totalGuests >= 6
                                        "
                                        class="room-selector-popup-increase w-8 h-8 bg-primary text-ivory-900 flex items-center justify-center disabled:opacity-50 rounded-sm"
                                        aria-label="Increase adults"
                                        :aria-disabled="
                                            room.adults >= 4 || totalGuests >= 6
                                        "
                                    >
                                        <PlusIcon />
                                    </button>
                                </div>
                            </div>
                            <hr class="my-4 border-stone-100" />
                            <div class="flex flex-col space-y-2">
                                <DynamicButton
                                    variant="outline"
                                    size="medium"
                                    @click="addRoom"
                                    :disabled="rooms.length >= 4"
                                    aria-label="Add room"
                                >
                                    <template #icon-before>
                                        <span class="w-4">
                                            <PlusIcon width="size-4" />
                                        </span>
                                    </template>
                                    {{ $t("common.addRoom") }}
                                </DynamicButton>
                                <DynamicButton
                                    variant="solid"
                                    size="medium"
                                    additionalClasses="room-selector-popup-confirm"
                                    @click="confirm"
                                    aria-label="Confirm room selection"
                                >
                                    {{ $t("common.complete") }}
                                </DynamicButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <PopupModel
            :visible="modalVisible"
            :title="$t('guestSelection.maxGuestsTitle')"
            @close="modalVisible = false"
        >
            <span v-html="$t('guestSelection.maxGuestsMessage')"></span>
            <template #buttons>
                <DynamicButton
                    variant="solid"
                    @click="closeModel"
                    additionalClasses="mt-4"
                    aria-label="Close guest selection modal"
                >
                    {{ $t("common.close") }}
                </DynamicButton>
            </template>
        </PopupModel>
    </div>
</template>

<script setup lang="ts">
import {
    ref,
    computed,
    reactive,
    watch,
    onMounted,
    onUnmounted,
    nextTick,
} from "vue";
import { useSwipeDownClose } from "@/composables/useSwipeDownClose";

import PopupModel from "@/components/global/PopupModel.vue";
import DynamicButton from "@/components/forms/DynamicButton.vue";
import DownArrowIcon from "@/components/icons/DownArrowIcon.vue";
import CompleteIcon from "@/components/icons/CompleteIcon.vue";
import PlusIcon from "@/components/icons/PlusIcon.vue";
import MinusIcon from "@/components/icons/MinusIcon.vue";

interface Props {
    modelValue?: { adults: number }[];
    maxAdults: number;
}

const props = defineProps<Props>();
const maxGuestsPerRoom = props.maxAdults || 4;
const maxRooms = 4;
const maxTotalGuests = 6;

const emit = defineEmits(["update:modelValue"]);

const popoverVisible = ref(false);
const modalVisible = ref(false);

const touchControlRef = ref(null);

const rooms = reactive(
    props.modelValue?.length
        ? props.modelValue.map((room) => ({ adults: room.adults || 1 }))
        : [{ adults: 1 }]
);

const initialRooms = JSON.stringify(rooms);

const selectWrapperClasses = computed(() => {
    return popoverVisible.value ? "rounded-t-md border-b-0" : "rounded-md";
});

const summary = computed(() => {
    const roomCount = rooms.length;
    const guestCount = rooms.reduce((acc, room) => acc + room.adults, 0);
    const roomLabel = roomCount === 1 ? "room" : "rooms";
    const guestLabel = guestCount === 1 ? "guest" : "guests";
    return `${roomCount} ${roomLabel} • ${guestCount} ${guestLabel}`;
});

const hasRoomOrGuestChanges = computed(() => {
    return JSON.stringify(rooms) !== initialRooms;
});

const totalGuests = computed(() => {
    return rooms.reduce((acc, room) => acc + room.adults, 0);
});

const addRoom = () => {
    if (rooms.length < maxRooms && totalGuests.value < maxTotalGuests) {
        rooms.push({ adults: 2 });
    } else {
        showModal();
    }
};

const removeRoom = (index: number) => {
    window.removeEventListener("click", handleClickOutside);
    rooms.splice(index, 1);
    checkTotalGuests();

    nextTick(() => {
        window.addEventListener("click", handleClickOutside);
    });
};

const showModal = () => {
    modalVisible.value = true;
};

const closeModel = () => {
    window.removeEventListener("click", handleClickOutside);
    modalVisible.value = false;

    nextTick(() => {
        window.addEventListener("click", handleClickOutside);
    });
};

const closeRoomSelector = () => {
    popoverVisible.value = false;
};

const confirm = () => {
    emit("update:modelValue", rooms);
    closeRoomSelector();
};

const increment = (index: number) => {
    const currentGuests = totalGuests.value;
    if (
        rooms[index].adults < maxGuestsPerRoom &&
        currentGuests < maxTotalGuests
    ) {
        rooms[index].adults++;
    } else {
        showModal();
    }
};

const decrement = (index: number) => {
    if (rooms[index].adults > 1) {
        rooms[index].adults--;
        emit("update:modelValue", rooms);
    }
};

const checkTotalGuests = () => {
    if (totalGuests.value >= 6) {
        showModal();
    }
};

watch(
    rooms,
    () => {
        emit("update:modelValue", rooms);
    },
    { deep: true }
);

const roomSelectorRef = ref<HTMLElement | null>(null);
const popupRef = ref<HTMLElement | null>(null);

const handleClickOutside = (event: Event) => {
    const target = event.target as Node;

    if (
        popupRef.value &&
        !popupRef.value.contains(target) &&
        roomSelectorRef.value &&
        !roomSelectorRef.value.contains(target) &&
        !(target as Element).closest(".room-selector-popup-item-remove")
    ) {
        closeRoomSelector();
    }
};

const handleEscape = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
        closeRoomSelector;
    }
};

onMounted(() => {
    window.addEventListener("keydown", handleEscape);
    window.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
    window.removeEventListener("keydown", handleEscape);
    window.removeEventListener("click", handleClickOutside);
});

useSwipeDownClose(roomSelectorRef, closeRoomSelector);
</script>

<style scoped lang="scss">
.room-selector-popup-increase,
.room-selector-popup-decrease {
    svg {
        @apply w-3;
    }
}
</style>