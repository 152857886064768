<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        :stroke="color"
        :class="width"
        aria-labelledby="plusIconTitle plusIconDesc"
        role="img"
    >
        <title id="plusIconTitle">Plus Icon</title>
        <desc id="plusIconDesc">Plus Icon</desc>
        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
        />
    </svg>
</template>

<script setup lang="ts">
interface Props {
    color?: string;
    width?: string;
}

const props = defineProps<Props>();

const color = props.color || "currentColor";
const width = props.width || "size-6";
</script>

<style scoped lang="scss"></style>
