<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26.4 18.99"
        aria-labelledby="cartRoomIconTitle cartRoomIconDesc"
        role="img"
        class="size-6"
    >
        <title id="cartRoomIconTitle">Room Icon</title>
        <desc id="cartRoomIconDesc">Room Icon</desc>
        <path
            d="M24.7 8.87h-.2V1.56C24.5.7 23.82 0 22.98 0H3.42C2.58 0 1.9.7 1.9 1.56v7.31h-.2c-.94 0-1.7.7-1.7 1.56v5.54c0 .28.22.5.5.5h1.4v2.02c0 .28.22.5.5.5s.5-.22.5-.5v-2.02h20.6v2.02c0 .28.22.5.5.5s.5-.22.5-.5v-2.02h1.4c.28 0 .5-.22.5-.5v-5.54c0-.86-.76-1.56-1.7-1.56ZM2.9 1.56c0-.31.23-.56.52-.56h19.56c.29 0 .52.25.52.56v7.31h-1.84c.02-.11.03-.22.03-.34v-.86c0-1.19-.97-2.16-2.16-2.16h-3.61c-1.19 0-2.16.97-2.16 2.16v.86c0 .12.02.23.03.34h-1.18c.02-.11.03-.22.03-.34v-.86c0-1.19-.97-2.16-2.16-2.16H6.87c-1.19 0-2.16.97-2.16 2.16v.86c0 .12.02.23.03.34H2.9V1.56Zm17.79 6.11v.86c0 .12-.03.23-.07.34h-5.8c-.03-.11-.07-.22-.07-.34v-.86c0-.64.52-1.16 1.16-1.16h3.61c.64 0 1.16.52 1.16 1.16h.01Zm-9.05 0v.86c0 .12-.03.23-.07.34h-5.8c-.03-.11-.07-.22-.07-.34v-.86c0-.64.52-1.16 1.16-1.16h3.61c.64 0 1.16.52 1.16 1.16h.01Zm13.76 7.81H1v-5.04c0-.3.32-.56.7-.56h22.99c.38 0 .7.26.7.56v5.04h.01Z"
            :style="`fill: ${color}; stroke-width: 0`"
        />
    </svg>
</template>

<script setup lang="ts">
interface Props {
    color?: string;
}

const props = defineProps<Props>();

const color = props.color || "currentColor";
</script>

<style scoped lang="scss"></style>
