import { useUserStore } from "@/stores/auth/user";
import type { RouteLocationRaw } from "vue-router";
import { baseGate } from "@m-media/vue3-gate-keeper";
import { usePreArrivalStore } from "@/stores/preArrival/preArrival";

/** A middleware that checks if the user is authenticated */
export default class extends baseGate {
    async handle() {
        const userStore = useUserStore();
        const preArrivalStore = usePreArrivalStore();

        userStore.state.isPreArrivalUser = true;

        if (!userStore.state.isAuthenticated) {
            return this.fail();
        }
    }

    route(): false | RouteLocationRaw {
        const userStore = useUserStore();

        if (!userStore.state.isAuthenticated) {
            return {
                name: "Login"
            };
        }

        return false;
    }
}
