import { reactive } from "vue";
import { defineStore } from "pinia";
import api from "@/api/axios";
import { useBookingStore } from "@/stores/booking/booking";
import { useSystemStore } from "@/stores/system/system";
import type { Error, DiningVenue } from "@/types/index";
import { useResetStore } from "@/helpers/useResetStore";
import { useRouter } from "vue-router";
import * as Sentry from "@sentry/vue";

interface DiningState {
    isLoading: boolean;
    venues: DiningVenue[];
    timeslots: any[];
    promotions: any[]
    diningType: string;
    selectedVenueName: string | null;
    selectedAreaId: number | null;
    selectedPromotionId: number | null;
    selectedPromotionName: string | null;
    selectedPromotionPrice: number | null;
    selectedTimeslot: string | null;
    specialRequestsNote: string;
    bookedPromotionId: number | null;
    error: Error;
}

const initialState = (): DiningState => ({
    isLoading: false,
    venues: [],
    timeslots: [],
    promotions: [],
    diningType: "Afternoon Tea",
    selectedVenueName: null,
    selectedAreaId: null,
    selectedPromotionId: null,
    selectedPromotionName: null,
    selectedPromotionPrice: null,
    selectedTimeslot: null,
    specialRequestsNote: "",
    bookedPromotionId: null,
    error: {
        hasError: false,
        message: "",
    },
});

export const useDiningStore = defineStore(
    "dining",
    () => {
        const state = reactive<DiningState>(initialState());
        const bookingStore = useBookingStore();
        const systemStore = useSystemStore();
        const resetStore = useResetStore();
        const router = useRouter();

        async function fetchDiningAvailabilities(): Promise<boolean> {
            try {
                const response = await api.post("api/dining/availability", {
                    webfolio_id: systemStore.state.webfolioId,
                    venue_name: state.selectedVenueName,
                    visit_date: bookingStore.state.booking.start_date,
                    party_size: bookingStore.state.booking.guests,
                    area_id: state.selectedAreaId,
                });

                state.timeslots = response.data.data.timeslots;
                state.promotions = response.data.data.promotions;

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
               
                return false;
            }
        }

        async function fetchDiningVenues(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/dining/venues", {
                    webfolio_id: systemStore.state.webfolioId,
                });

                const venues = response.data.data;

                state.venues = venues;

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function createDiningReservation(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/dining/book", {
                    booking_id: bookingStore.state.booking.id,
                    webfolio_id: systemStore.state.webfolioId,
                    session_id: systemStore.state.sessionId,
                    venue_name: state.selectedVenueName,
                    visit_date: bookingStore.state.booking.start_date,
                    party_size: bookingStore.state.booking.guests,
                    visit_time: state.selectedTimeslot,
                    special_requests: state.specialRequestsNote,
                    is_leave_time_confirmed: "Y",
                    promotion_id: state.selectedPromotionId,
                    dining_type: state.diningType,
                    promotion_name: state.selectedPromotionName,
                    price: state.selectedPromotionPrice,
                });

                if (response) {
                    bookingStore.state.booking = response.data.data;
                    state.bookedPromotionId = state.selectedPromotionId;

                    push.success({
                        title: "Success!",
                        message:
                            "Your Afternoon Tea has been reserved, pending completion of your booking.",
                    });
                }

                return true;
            } catch (errorResponse: any) {
                const isSessionExpired =
                    errorResponse?.response?.data?.session_expired;

                if (isSessionExpired) {
                    push.error({
                        title: "Session Expired",
                        message:
                            "Your session has expired, please start your booking again.",
                    });

                    resetStore.all();
                    await systemStore.systemStatus();
                    await systemStore.createSession();
                    router.push({ name: "Index" });

                    return false;
                }

                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function removeDiningReservation(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/dining/cancel", {
                    booking_id: bookingStore.state.booking.id,
                    webfolio_id: systemStore.state.webfolioId,
                    session_id: systemStore.state.sessionId,
                    booking_dining_item_id:
                        bookingStore.state.booking.dining_items[0].id,
                    reservation_id:
                        bookingStore.state.booking.dining_items[0]
                            .dining_reservation.id,
                });

                if (response) {
                    state.bookedPromotionId = null;
                    bookingStore.state.booking = response.data.data;

                    push.success({
                        title: "Success!",
                        message: "Your Afternoon Tea reservation has been cancelled.",
                    });
                }

                return true;
            } catch (errorResponse: any) {
                const isSessionExpired =
                    errorResponse?.response?.data?.session_expired;

                if (isSessionExpired) {
                    push.error({
                        title: "Session Expired",
                        message:
                            "Your session has expired, please start your booking again.",
                    });

                    resetStore.all();
                    await systemStore.systemStatus();
                    await systemStore.createSession();
                    router.push({ name: "Index" });

                    return false;
                }

                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        const logErrorDetails = (
            errorResponse: any,
            defaultMessage: string
        ) => {
            const errorMessage =
                errorResponse.response?.data?.message || defaultMessage;
            const errorStatus =
                errorResponse.response?.status || "Unknown status";
            const errorData =
                errorResponse.response?.data || "No additional data";

            console.error("Error Message:", errorMessage);
            console.error("Error Status:", errorStatus);
            console.error("Error Data:", errorData);

            // Capture the error in Sentry
            Sentry.captureException(new Error(errorMessage), {
                extra: {
                    status: errorStatus,
                    data: errorData,
                },
            });
        };


        const $reset = () => {
            Object.assign(state, initialState());
        };

        return {
            state,
            fetchDiningAvailabilities,
            fetchDiningVenues,
            createDiningReservation,
            removeDiningReservation,
            $reset,
        };
    },
    {
        persist: {
            storage: sessionStorage,
        },
    }
);
