import { useRoute } from "vue-router";
import type { RouteLocationRaw } from "vue-router";
import { baseGate } from "@m-media/vue3-gate-keeper";
import { useBookingStore } from "@/stores/booking/booking";

/** A middleware that checks if the user is authenticated */
export default class extends baseGate {
    async handle() {
        const booking = useBookingStore();

        if (booking.state.booking.status === "complete") {
            return this.fail();
        }
    }

    route(): false | RouteLocationRaw {
        const route = useRoute();

        return {
            name: "Order Confirmation"
        };
    }
}
