import { getActivePinia } from "pinia";
import type { Pinia, Store } from "pinia";

interface ExtendedPinia extends Pinia {
    _s: Map<string, Store>;
}

export function useResetStore() {
    const pinia = getActivePinia() as ExtendedPinia;

    if (!pinia) {
        throw new Error("There is no active Pinia instance");
    }

    const resetStore: Record<string, () => void> = {};

    pinia._s.forEach((store, name) => {
        if (typeof store.$reset === "function") {
            resetStore[name] = () => store.$reset();
        }
    });

    resetStore.all = () =>
        pinia._s.forEach((store) => {
            if (typeof store.$reset === "function") {
                store.$reset();
            }
        });

    return resetStore;
}
