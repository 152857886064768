<template>
    <div :class="containerClass">
        <h2 v-if="heading" :class="headingClass" role="heading" aria-level="2">
            {{ heading }}
        </h2>
        <h3
            v-if="subheading"
            :class="subheadingClass"
            role="heading"
            aria-level="3"
        >
            {{ subheading }}
        </h3>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
    heading?: string;
    subheading?: string;
    type?:
        | "pagetitle"
        | "sectiontitle"
        | "filtertitle"
        | "service"
        | "gift"
        | "category"
        | "giftcard"
        | "primary"
        | "secondary"
        | "tertiary"
        | "cart"
        | "login";
    [key: string]: any;
}

const props = defineProps({
    heading: String,
    subheading: String,
    type: { type: String, default: "primary" },
});

const containerClass = computed(() => {
    return {
        "booking-title flex flex-col": true,
        "gap-2": props.type === "sectiontitle",
        "px-6 pt-6 w-full bg-ivory-900 md:bg-transparent md:p-0 md:mb-8":
            props.type === "category",
        "w-full": props.type === "giftcard",
    };
});

const headingClass = computed(() => {
    return {
        "font-subheading font-bold uppercase text-lg md:text-xl":
            props.type === "pagetitle",
        "font-heading font-bold text-xl": props.type === "sectiontitle",
        "font-subheading font-semibold uppercase text-md pb-3 border-b border-stone-900 border-solid":
            props.type === "category" || props.type === "giftcard",
        "font-heading text-[24px] leading-7 line-clamp-2 text-[#212529]":
            props.type === "experience",
        "font-subheading text-sm leading-4 font-bold uppercase line-clamp-2":
            props.type === "service",
        "font-subheading text-[20px] leading-7 leading-4 font-bold uppercase line-clamp-2":
            props.type === "cart",
        "font-heading text-lg line-clamp-1": props.type === "gift",
        "font-heading text-[28px] leading-9 text-[#212529] tracking-tight":
            props.type === "primary",
        "font-heading font-semibold text-lg":
            props.type === "secondary" || props.type === "tertiary",
        "font-subheading font-semibold text-lg text-black-600":
            props.type === "login",
    };
});

const subheadingClass = computed(() => {
    return {
        "font-body text-base text-black-600/50 leading-6 tracking-tight":
            props.type === "pagetitle",
        "font-body text-lg font-bold text-primary leading-6 tracking-tight":
            props.type === "filtertitle",
        "font-heading text-lg": props.type === "sectiontitle",
        "font-subheading font-bold text-sm uppercase line-clamp-2":
            props.type === "service",
        "font-subheading text-[20px] text-primary font-bold uppercase leading-6 | md:text-[18px] leading-[14px] md:capitalize":
            props.type === "primary",
        "font-subheading text-base text-primary font-bold leading-6 | md:text-[18px] leading-[14px]":
            props.type === "cart",
        "font-subheading font-bold text-md": props.type === "secondary",
        "font-subheading font-bold uppercase text-sm":
            props.type === "tertiary",
        "font-subheading font-semibold text-sm text-black-300":
            props.type === "login",
    };
});
</script>

<style scoped lang="scss"></style>
