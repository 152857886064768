import { ref, onMounted, onUnmounted } from "vue";

export function useSwipeDownClose(
    refElement: any,
    onClose: () => void,
    threshold = 50
) {
    const startY = ref(0);

    const handleTouchStart = (event: TouchEvent) => {
        startY.value = event.touches[0].clientY;
    };

    const handleTouchEnd = (event: TouchEvent) => {
        const endY = event.changedTouches[0].clientY;
        const movementY = endY - startY.value;

        if (movementY > threshold) {
            onClose();
        }
    };

    const attachTouchListeners = () => {
        const element = refElement.value;
        if (element) {
            element.addEventListener("touchstart", handleTouchStart);
            element.addEventListener("touchend", handleTouchEnd);
        }
    };

    const detachTouchListeners = () => {
        const element = refElement.value;
        if (element) {
            element.removeEventListener("touchstart", handleTouchStart);
            element.removeEventListener("touchend", handleTouchEnd);
        }
    };

    onMounted(() => attachTouchListeners());
    onUnmounted(() => detachTouchListeners());
}
