<template>
    <div :class="cardClasses" role="group" :aria-labelledby="headingId">
        <BookingImage
            v-if="props.src"
            :src="src"
            :background="true"
            variant="cover"
            :class="imageClasses"
        />
        <div class="flex-1">
            <div
                v-if="hasHeading"
                class="pt-8 pb-6 px-6 | md:w-full md:pt-12 md:px-10 md:pb-8"
                :id="headingId"
            >
                <BookingTitle :subheading="heading" type="primary" />
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import BookingTitle from "@/components/global/BookingTitle.vue";
import BookingImage from "@/components/global/BookingImage.vue";

interface Props {
    heading?: string;
    subheading?: string;
    src?: string | (() => string);
    imageAlt?: string;
    imageBackground?: boolean;
    titleContainerClasses?: string;
    headingClasses?: string;
    subheadingClasses?: string;
    imageClasses?: string;
    additionalClasses?: string;
    variant?:
        | "basic"
        | "bookingpage"
        | "accountpage"
        | "loginpage"
        | "registerpage";
}

const props = defineProps<Props>();
const headingId = ref(`heading-${Math.random().toString(36).substring(2, 11)}`);
const hasHeading = computed(() => props.heading || props.subheading);
const imageAlt = computed(() => props.imageAlt || "Default alt text");
const variant = props.variant || "basic";

const additionalClasses = props.additionalClasses || "";

const cardClasses = computed(() => {
    const baseClass =
        "relative w-full md:border md:border-stone-900 min-h-[300px] md:min-h-[400px]";

    let variantClasses: Record<string, string>;

    variantClasses = {
        basic: `mx-auto`,
        bookingpage: `booking-page relative bg-ivory-550 | md:max-w-[900px] md:mx-auto md:flex md:flex-wrap md:mt-20`,
        registerpage: `account-page max-w-md mx-auto | md:max-w-[900px] md:flex md:flex-wrap md:border-0`,
        accountpage: `border-0 account-page relative | md:border-0 md:max-w-[900px] md:mx-auto md:flex md:flex-wrap`,
        loginpage: `login-page max-w-md mx-auto | md:max-w-[600px] md:mx-auto md:flex md:flex-wrap md:border-0`,
    };

    return `${baseClass} ${variantClasses[variant]} ${additionalClasses}`;
});
</script>

<style scoped lang="scss"></style>
