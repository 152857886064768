import { reactive } from "vue";
import { defineStore } from "pinia";
import type { Rate, Room, Error } from "@/types/index";
import api from "@/api/axios";
import { useBookingStore } from "@/stores/booking/booking";
import { useSystemStore } from "@/stores/system/system";
import * as Sentry from "@sentry/vue";
interface RatesState {
    isLoading: boolean;
    ratesMap: Record<number, Rate[]>;
    error: Error;
}

const initialState = (): RatesState => ({
    isLoading: false,
    ratesMap: {},
    error: {
        hasError: false,
        message: "",
    },
});

export const useRatesStore = defineStore(
    "rates",
    () => {
        const state = reactive<RatesState>(initialState());

        const getRateRooms = (
            roomIndex: number,
            rateCode: string
        ): Room[] | [] => {
            const rate = state.ratesMap[roomIndex]?.find(
                (rate) => rate.rate_code === rateCode
            );

            return rate?.rooms || [];
        };

        const fetchRates = async (
            roomIndex: number,
            adults: number,
            promoCode: string | null = null
        ): Promise<Rate[] | void> => {
            state.isLoading = true;
            state.error = { hasError: false, message: "" };

            try {
                const bookingStore = useBookingStore();
                const systemStore = useSystemStore();

                const response = await api.post("api/pms/rates", {
                    booking_id: bookingStore.state.booking.id,
                    webfolio_id: systemStore.state.webfolioId,
                    adults: adults,
                    venue_id: bookingStore.getVenueId(),
                    start_date: bookingStore.state.booking.start_date,
                    end_date: bookingStore.state.booking.end_date,
                    promo_code: promoCode,
                });

                const ratesData: Rate[] = response.data.data;
                state.ratesMap[roomIndex] = ratesData;

                await Promise.all(
                    state.ratesMap[roomIndex].map(async (rate) => {
                        try {
                            const roomsResponse = await api.post(
                                "api/pms/rooms",
                                {
                                    venue_id: bookingStore.getVenueId(),
                                    webfolio_id: systemStore.state.webfolioId,
                                    start_date:
                                        bookingStore.state.booking.start_date,
                                    end_date:
                                        bookingStore.state.booking.end_date,
                                    rate_code: rate.rate_code,
                                    adults: adults,
                                }
                            );

                            const roomsData: Room[] = roomsResponse.data.data;
                            rate.rooms = roomsData;

                            if (rate.rooms.length > 0) {
                                const cheapestRoomPrice = rate.rooms.reduce(
                                    (minPrice, room) => {
                                        return room.amount < minPrice
                                            ? room.amount
                                            : minPrice;
                                    },
                                    Infinity
                                );
                                rate.lowest_room_price = cheapestRoomPrice;
                            }
                        } catch (roomError: any) {
                            logErrorDetails(
                                roomError,
                                "An error occurred fetching rooms for rate."
                            );

                            console.error(
                                "Error fetching rooms for rate:",
                                rate.rate_code,
                                roomError.message
                            );

                            rate.rooms = [];
                        }
                    })
                );

                state.ratesMap[roomIndex] = state.ratesMap[roomIndex].filter(
                    (rate) => rate.rooms && rate.rooms.length > 0
                );

                return state.ratesMap[roomIndex];
            } catch (error: any) {
                logErrorDetails(error, "An error occurred fetching rates.");
                state.error = {
                    hasError: true,
                    message: "Failed to load rates. Please try again later.",
                };
                return;
            } finally {
                state.isLoading = false;
            }
        };

        const logErrorDetails = (
            errorResponse: any,
            defaultMessage: string
        ) => {
            const errorMessage =
                errorResponse.response?.data?.message || defaultMessage;
            const errorStatus =
                errorResponse.response?.status || "Unknown status";
            const errorData =
                errorResponse.response?.data || "No additional data";

            console.error("Error Message:", errorMessage);
            console.error("Error Status:", errorStatus);
            console.error("Error Data:", errorData);

            Sentry.captureException(new Error(errorMessage), {
                extra: {
                    status: errorStatus,
                    data: errorData,
                },
            });
        };


        const $reset = () => {
            Object.assign(state, initialState());
        };

        return {
            state,
            fetchRates,
            getRateRooms,
            $reset,
        };
    },
    {
        persist: {
            storage: sessionStorage,
        },
    }
);
