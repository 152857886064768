<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        :stroke="color"
        class="size-6"
        aria-labelledby="minusIconTitle minusIconDesc"
        role="img"
    >
        <title id="minusIconTitle">Minus Icon</title>
        <desc id="minusIconDesc">Minus Icon</desc>
        <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
    </svg>
</template>

<script setup lang="ts">
interface Props {
    color?: string;
}

const props = defineProps<Props>();

const color = props.color || "currentColor";
</script>

<style scoped lang="scss"></style>
