import { useUserStore } from "@/stores/auth/user";
import { useRoute } from "vue-router";
import type { RouteLocationRaw } from "vue-router";
import { baseGate } from "@m-media/vue3-gate-keeper";

/** A middleware that checks if the user is authenticated */
export default class extends baseGate {
    async handle() {
        const userStore = useUserStore();

        if (!userStore.state.isAuthenticated) {
            return this.fail();
        }
    }

    route(): false | RouteLocationRaw {
        const route = useRoute();

        return {
            name: "Login",
            query: {
                redirect: route.fullPath
            }
        };
    }
}
