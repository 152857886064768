import { reactive } from "vue";
import { defineStore } from "pinia";
import type { AvailabilityRate, Error } from "@/types/index";
import api from "@/api/axios";
import { useBookingStore } from "@/stores/booking/booking";
import moment from "moment";

interface CalendarState {
    isLoading: boolean;
    dates: AvailabilityRate[];
    startDate: string;
    endDate: string;
    error: Error;
}

const initialState = (): CalendarState => ({
    isLoading: false,
    dates: [],
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().add(6, "months").format("YYYY-MM-DD"),
    error: {
        hasError: false,
        message: "",
    },
});

export const useCalendarStore = defineStore(
    "calendar",
    () => {
        const state = reactive<CalendarState>(initialState());

        async function fetchCalendar(
            startDate?: string,
            endDate?: string
        ): Promise<boolean> {
            state.isLoading = true;

            try {
                const bookingStore = useBookingStore();
                const response = await api.post("api/calendar/rates", {
                    stay_type: bookingStore.state.booking.experience,
                    venue_id: bookingStore.getVenueId(),
                    start_date: startDate || state.startDate,
                    end_date: endDate || state.endDate,
                    min_length:
                        bookingStore.state.booking.experience === "stay" ||
                        bookingStore.state.booking.experience === "hotel_stay"
                            ? 1
                            : 0,
                });

                state.dates = response.data.data;

                if (startDate) state.startDate = startDate;
                if (endDate) state.endDate = endDate;

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        const $reset = () => {
            Object.assign(state, initialState());
        };

        return {
            state,
            fetchCalendar,
            $reset,
        };
    },
    {
        persist: {
            storage: sessionStorage,
        },
    }
);
