<template>
    <div class="cart-item p-4 bg-cart">
        <div
            class="flex justify-between items-center mb-2 py-2 border-b border-stone-100"
        >
            <h2 class="font-subheading font-bold uppercase text-[12px]">
                {{ formatTitle(item.promotion_name, 20) }}
            </h2>

            <p
                class="font-subheading text-[16px] font-semibold"
                aria-live="polite"
            >
                £{{ item.total }}
            </p>
        </div>

        <div class="flex justify-between items-center mb-4 text-sm mt-4">
            <IconWithText
                iconPosition="top"
                role="listitem"
                aria-label="mins"
                variant="autowidth"
            >
                <template #icon>
                    <ExperienceCartIcon />
                </template>
                <span class="font-sans text-xxs font-light line-clamp-1">
                    {{ item.dining_type }}
                </span>
            </IconWithText>
            <IconWithText
                iconPosition="top"
                role="listitem"
                aria-label="mins"
                variant="autowidth"
            >
                <template #icon>
                    <GuestIcon />
                </template>
                <span class="font-sans text-xxs font-light line-clamp-1">
                    £{{ item.promotion_price }}
                    <small>({{ $t("common.perguest") }})</small>
                </span>
            </IconWithText>
        </div>

        <DynamicButton
            @click="removeDiningReservation"
            variant="text"
            size="none"
            aria-label="Remove dining item"
        >
            <span class="text-danger-700 text-xs capitalize underline">
                {{ $t("common.removeDining") }}
            </span>
        </DynamicButton>
    </div>

    <PopupModel
        :visible="confirmationModalVisible"
        :title="$t('reviewBooking.confirmTitle')"
        @close="confirmationModalVisible = false"
    >
        {{ $t("reviewBooking.confirmMessage") }}
        <template #buttons>
            <DynamicButton
                variant="solid"
                @click="confirmRemoval(true)"
                additionalClasses="mt-4"
                aria-label="Close guest selection modal"
            >
                {{ $t("common.remove") }}
            </DynamicButton>
            <DynamicButton
                variant="outline"
                @click="confirmRemoval(false)"
                aria-label="Close guest selection modal"
            >
                {{ $t("common.cancel") }}
            </DynamicButton>
        </template>
    </PopupModel>

    <LoadingLogo
        v-if="isLoading"
        :loading="isLoading"
        :message="$t('common.updatingBooking')"
    />
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useDiningStore } from "@/stores/dining/dining";
import { useBookingStepsStore } from "@/stores/booking/bookingSteps";
import type { BookingDiningItem } from "@/types";
import IconWithText from "@/components/global/IconWithText.vue";
import DynamicButton from "@/components/forms/DynamicButton.vue";
import ExperienceCartIcon from "@/components/icons/ExperienceCartIcon.vue";
import GuestIcon from "@/components/icons/GuestIcon.vue";
import PopupModel from "@/components/global/PopupModel.vue";
import LoadingLogo from "@/components/global/LoadingLogo.vue";

interface Props {
    item: BookingDiningItem;
}

const bookingStepsStore = useBookingStepsStore();
const diningStore = useDiningStore();
const props = defineProps<Props>();
const confirmationModalVisible = ref(false);
const resolveConfirmation = ref<((isConfirmed: boolean) => void) | null>(null);
const isLoading = ref(false);

const formatTitle = (title: string, maxLength: number) => {
    return title.length > maxLength ? `${title.slice(0, maxLength)}…` : title;
};

const confirmRemoval = (isConfirmed: boolean) => {
    confirmationModalVisible.value = false;
    if (resolveConfirmation.value) {
        resolveConfirmation.value(isConfirmed);
    }
};

const showConfirmationModal = () => {
    confirmationModalVisible.value = true;

    return new Promise((resolve) => {
        resolveConfirmation.value = resolve;
    });
};

const removeDiningReservation = async () => {
    try {
        const isConfirmed = await showConfirmationModal();

        if (!isConfirmed) {
            return;
        }

        isLoading.value = true;

        await diningStore.removeDiningReservation();
        isLoading.value = false;
        await bookingStepsStore.resetToStep(1);
    } catch (error) {
        console.error("Error removing dining reservation", error);

        throw error;
    }
};
</script>
