<template>
    <svg
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26.22 16.55"
        class="size-6 fill-primary stroke-primary"
        aria-labelledby="ExpIconTitle ExpIconDesc"
        role="img"
    >
        <title id="ExpIconTitle">Experience Icon</title>
        <desc id="ExpIconDesc">Experience Icon</desc>
        <path
            d="M26.02 8.41c-.11-.08-1.83-1.35-4.24-1.68-.12-2.25-1.21-3.93-1.28-4.04l-.15-.23h-.28c-.11 0-1.73.07-3.43.94-1.27-2.12-3.18-3.2-3.3-3.27L13.1 0l-.24.13c-.12.06-1.94 1.1-3.21 3.12-1.6-.73-3.04-.78-3.14-.79h-.28l-.15.22c-.07.1-1.14 1.76-1.27 3.99C2.22 6.91.33 8.31.22 8.4L0 8.57l.02.27c.02.21.49 5.07 4.9 7.02 1.13.5 2.22.69 3.21.69 2.41 0 4.28-1.09 4.98-1.56.7.47 2.57 1.56 4.98 1.56 1 0 2.08-.19 3.21-.69 4.42-1.96 4.88-6.82 4.9-7.02l.02-.27L26 8.4h.02Zm-12.03 5.96s.08-.06.13-.09a10.504 10.504 0 0 0 1.2-1.03c1.05-1.06 2.18-2.68 2.42-4.92.07-.03.13-.07.2-.1.98-.43 1.94-.58 2.83-.57.17 0 .34.01.5.03.17.01.34.03.5.06 1.63.25 2.91.97 3.42 1.3-.17 1.01-.97 4.45-4.27 5.91-3.06 1.36-5.78.1-6.91-.59h-.02Zm-8.66.59c-3.3-1.46-4.1-4.91-4.27-5.91.54-.35 1.96-1.15 3.76-1.34.17-.02.33-.03.5-.04h.5c.79.03 1.63.19 2.48.57.06.02.1.05.16.08.23 2.18 1.29 3.78 2.32 4.84.23.24.45.45.67.63.27.23.51.42.72.56.01.01.03.02.04.03-1.16.68-3.88 1.91-6.89.58ZM9.16 4.15c-.41.88-.69 1.9-.72 3.06a7.6 7.6 0 0 0-2.62-.55c.11-1.48.67-2.67.95-3.17.45.05 1.39.21 2.39.66Zm1.38-.41a8.912 8.912 0 0 1 2.56-2.58c.52.34 1.77 1.27 2.67 2.76.09.14.17.29.25.45.08.15.15.31.22.48.32.76.54 1.63.54 2.62 0 .09 0 .17-.01.25 0 .21-.02.42-.05.62-.03.24-.07.47-.12.69-.39 1.7-1.35 2.94-2.21 3.75-.13.12-.26.24-.38.34-.06.05-.11.09-.17.13-.08.07-.16.13-.24.18-.04.03-.08.06-.11.08-.1.07-.19.13-.27.18-.04.03-.09.06-.12.08-.02-.02-.06-.04-.09-.06-.04-.03-.09-.06-.14-.09-.04-.03-.09-.06-.13-.09-.09-.06-.18-.13-.28-.21a.265.265 0 0 1-.06-.05c-.11-.08-.22-.18-.33-.28-.91-.8-2.05-2.13-2.48-4.01-.05-.22-.09-.44-.12-.68-.02-.2-.04-.41-.05-.62 0-.08-.01-.15-.01-.23 0-1.08.25-2.02.63-2.83.07-.16.15-.31.23-.45.08-.15.17-.29.26-.43h.01Zm9.28-.25c.27.49.84 1.69.95 3.16-.94 0-1.95.15-2.99.58-.03-1.1-.28-2.08-.65-2.93a8.02 8.02 0 0 1 2.7-.81h-.01Z"
            stroke-width="0"
        />
    </svg>
</template>

<script setup lang="ts">
interface Props {
    color?: string;
}

const props = defineProps<Props>();

const color = props.color || "currentColor";
</script>

<style scoped lang="scss"></style>
