<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        class="size-6"
        aria-labelledby="timerIconTitle timerIconDesc"
        role="img"
        viewBox="0 0 512 512"
    >
        <title id="timerIconTitle">Timer Icon</title>
        <desc id="timerIconDesc">Timer Icon</desc>
        <path
            d="M112.91 128A191.85 191.85 0 0064 254c-1.18 106.35 85.65 193.8 192 194 106.2.2 192-85.83 192-192 0-104.54-83.55-189.61-187.5-192a4.36 4.36 0 00-4.5 4.37V152"
            fill="none"
            :stroke="color"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="32"
        />
        <path
            d="M233.38 278.63l-79-113a8.13 8.13 0 0111.32-11.32l113 79a32.5 32.5 0 01-37.25 53.26 33.21 33.21 0 01-8.07-7.94z"
        />
    </svg>
</template>

<script setup lang="ts">
interface Props {
    color?: string;
}

const props = defineProps<Props>();

const color = props.color || "currentColor";
</script>

<style scoped lang="scss"></style>
