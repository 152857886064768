import { baseGate } from "@m-media/vue3-gate-keeper";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/auth/user";

/** A middleware that checks if the user is authenticated */
export default class extends baseGate {
    async handle() {
        const user = useUserStore();
        const router = useRouter();

        if (user.state.isAuthenticated && !user.state.user?.rs_customer_id) {
            await router.push({ name: "Complete Registration" });
        }
    }
}
