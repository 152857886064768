<template>
    <component
        :is="isLink ? 'a' : 'button'"
        :href="href || undefined"
        :type="isLink ? undefined : type"
        :class="buttonClasses"
        :disabled="props.disabled"
        v-bind="isLink ? { target: target, rel: rel, href: href } : {}"
        @click="createRipple"
        :aria-disabled="props.disabled ? 'true' : undefined"
        :tabindex="props.disabled ? -1 : undefined"
    >
        <slot name="icon-before"></slot>
        <span
            class="relative z-[1] empty:hidden font-medium flex-none flex justify-center flex-row items-center md:min-w-fit"
        >
            <slot></slot>
        </span>
        <slot name="icon-after"></slot>
    </component>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
    variant?: "text" | "solid" | "outline";
    size?: "none" | "small" | "medium" | "large";
    additionalClasses?: string;
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
    href?: string;
    target?: string;
    rel?: string;
    theme?: "eh" | "hch" | "default";
    color?:
        | "primary"
        | "secondary"
        | "accent"
        | "highlight"
        | "transparent"
        | "white"
        | "black"
        | "ivory";
}

const props = defineProps<Props>();

const variant = props.variant || "solid";
const size = props.size || "medium";
const color = props.color || "primary";
const isLink = computed(() => !!props.href);
const theme = props.theme;

const colorVariantMappings: Record<string, Record<string, string>> = {
    primary: {
        solid: "bg-primary text-white hover:bg-primary-hover",
        outline:
            "border border-primary text-primary hover:border-primary-hover hover:text-primary-hover bg-white",
        text: "text-primary hover:text-primary-hover",
    },
    secondary: {
        solid: "bg-secondary text-white hover:bg-secondary-hover",
        outline:
            "border border-secondary text-secondary hover:border-secondary-hover hover:text-secondary-hover",
        text: "text-secondary hover:text-secondary-hover",
    },
    accent: {
        solid: "bg-accent text-white hover:bg-accent-hover",
        outline:
            "border border-accent text-accent hover:border-accent-hover hover:text-accent-hover",
        text: "text-accent hover:text-accent-hover",
    },
    highlight: {
        solid: "bg-highlight text-white hover:bg-highlight-hover",
        outline:
            "border-highlight text-highlight hover:border-highlight-hover hover:text-highlight-hover",
        text: "text-highlight hover:text-highlight-hover",
    },
    transparent: {
        solid: "bg-transparent text-current",
        outline: "border-transparent text-current",
        text: "text-current",
    },
    white: {
        solid: "bg-[#F3F1EB] text-[#1B3C33]",
        outline:
            "border border-white text-white hover:border-white-hover hover:text-white-hover",
        text: "text-white hover:text-white",
    },
    black: {
        solid: "bg-black-600 text-ivory-900",
        outline:
            "border border-black-600 text-black-600 hover:border-black-900 hover:text-black-900",
        text: "text-black-600 hover:black-900",
    },
    ivory: {
        solid: "bg-ivory-900 text-black-600",
        outline:
            "border border-ivory-900 text-ivory-900 hover:border-ivory-900 hover:text-ivory-900",
        text: "text-ivory-900 hover:text-ivory-900",
    },
};

const getColorClasses = (color: string, variant: string) => {
    const colorClasses = colorVariantMappings[color];
    return colorClasses ? colorClasses[variant] : "";
};

const buttonClasses = computed(() => {
    const baseClass =
        "relative min-w-fit flex items-center gap-2 font-medium justify-center font-button uppercase whitespace-pre focus:outline-none transition duration-400 overflow-hidden text-base md:text-[14px]";

    const sizeClasses: Record<string, string> = {
        none: `p-0`,
        small: `py-2 px-3 md:px-4`,
        medium: `py-4 px-4 md:py-3 md:px-6`,
        large: `py-6 px-12`,
    };

    const disabledClass = props.disabled
        ? "disabled:opacity-50 disabled:cursor-not-allowed"
        : "";

    const colorClass = getColorClasses(color, variant);

    return `${baseClass} ${sizeClasses[size]} ${colorClass} ${disabledClass} ${
        props.additionalClasses || ""
    }`;
});

const createRipple = (event: MouseEvent) => {
    if (variant === "text") {
        return;
    }

    const button = event.currentTarget as HTMLElement;
    const ripple = document.createElement("span");

    const rect = button.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = event.clientX - rect.left - size / 2;
    const y = event.clientY - rect.top - size / 2;

    ripple.style.width = ripple.style.height = `${size}px`;
    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;
    ripple.className =
        "ripple absolute rounded-full scale-0 animate-ripple bg-ivory-900";

    button.appendChild(ripple);

    setTimeout(() => {
        ripple.remove();
    }, 600);
};
</script>

<style scoped lang="scss">
.ripple {
    @apply absolute rounded-full scale-0 animate-ripple bg-ivory-900;
    animation: ripple 0.6s linear;
}

@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}

:deep(a),
:deep(button) {
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
    }
}
</style>
