import axios from "axios";
import { useUserStore } from "@/stores/auth/user";
import { useSystemStore } from "@/stores/system/system";

const apiUrl = import.meta.env.VITE_BOOKING_API_URL;

const api = axios.create({
    baseURL: apiUrl,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

api.interceptors.request.use(
    (config) => {
        const systemStore = useSystemStore();
        const userStore = useUserStore();

        if (systemStore.state.sessionToken) {
            config.headers["JSESSIONID"] = systemStore.state.sessionToken;
        }

        if (userStore.state.user && userStore.state.user.token) {
            config.headers[
                "Authorization"
            ] = `Bearer ${userStore.state.user.token}`;
        }

        const awaid = localStorage.getItem("awaid");
        const utm_source = localStorage.getItem("utm_source");
        const gclid = localStorage.getItem("gclid");
        const source = localStorage.getItem("source");
        const awc = localStorage.getItem("awc");

        if (awaid) config.headers["awaid"] = awaid;
        if (utm_source) config.headers["utm_source"] = utm_source;
        if (gclid) config.headers["gclid"] = gclid;
        if (source) config.headers["source"] = source;
        if (awc) config.headers["awc"] = awc;

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

api.interceptors.response.use((response) => {
    if (
        response.config.url &&
        !response.config.url.includes("sanctum/csrf-cookie")
    ) {
        const systemStore = useSystemStore();
        systemStore.setSessionToken(response.data.session_token);
    }

    return response;
});

export default api;
