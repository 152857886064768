import { reactive, nextTick } from "vue";
import { defineStore } from "pinia";
import api from "@/api/axios";
import { useBookingStore } from "@/stores/booking/booking";
import { useSystemStore } from "@/stores/system/system";
import type {
    Treatment,
    TreatmentAvailability,
    TreatmentCategory,
    TreatmentFilter,
    Error,
    BookingSpaItem,
} from "@/types/index";
import moment from "moment";
import { emitEvent } from "@/helpers/eventEmitter";
import { useResetStore } from "@/helpers/useResetStore";
import { useRouter } from "vue-router";
import * as Sentry from "@sentry/vue";
interface SpaState {
    isLoading: boolean;
    categories: TreatmentCategory[];
    filters: TreatmentFilter[];
    treatments: Treatment[];
    treatmentAvailability: TreatmentAvailability[];
    selectedTreatmentDate: string | number | null;
    selectedTimeslot: TreatmentAvailability | null;
    selectedCategory: number | null | false;
    selectedFilters: number[];
    error: Error;
}

const initialState = (): SpaState => ({
    isLoading: false,
    categories: [],
    filters: [],
    treatments: [],
    treatmentAvailability: [],
    selectedTreatmentDate: null,
    selectedTimeslot: null,
    selectedCategory: null,
    selectedFilters: [],
    error: {
        hasError: false,
        message: "",
    },
});

export const useSpaStore = defineStore(
    "spa",
    () => {
        const state = reactive<SpaState>(initialState());
        const bookingStore = useBookingStore();
        const systemStore = useSystemStore();
        const resetStore = useResetStore();
        const router = useRouter();

        async function bookTreatment(
            treatment: Treatment,
            booking_pms_item_id: number | null = null,
            booking_pms_item_guest_id: number | null = null,
            is_inclusive: boolean = false,
            guestRequest: string | null = null
        ): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/treatment/book", {
                    booking_id: bookingStore.state.booking.id,
                    booking_pms_item_id: booking_pms_item_id,
                    booking_pms_item_guest_id: booking_pms_item_guest_id,
                    venue_id: bookingStore.state.booking.venue_id,
                    webfolio_id: systemStore.state.webfolioId,
                    session_id: systemStore.state.sessionId,
                    customer_id: systemStore.state.customerId,
                    spa_item_id: treatment.id,
                    spa_staff_id: state.selectedTimeslot?.spa_staff_id,
                    start_time: state.selectedTimeslot?.treatment_timestamp,
                    is_inclusive: is_inclusive,
                    guest_request: guestRequest,
                });

                bookingStore.state.booking = response.data.data;

                push.success({
                    title: "Booking Update",
                    message: `${treatment.title} added at ${
                        state.selectedTimeslot?.treatment_time
                    } on ${moment(
                        state.selectedTimeslot?.treatment_timestamp,
                        "YYYY-MM-DDHHmmss"
                    ).format("MMM Do")}.`,
                });

                if (response.data.analytics) {
                    emitEvent("analytics-event", {
                        type: "add_to_cart",
                        venue:
                            bookingStore.state.booking.venue_id === 100
                                ? "Hoar Cross Hall"
                                : "Eden Hall",
                        ecommerce: {
                            currency: "GBP",
                            add: {
                                products: [response.data.analytics.product],
                            },
                        },
                    });
                }

                return true;
            } catch (errorResponse: any) {
                logErrorDetails(
                    errorResponse,
                    "An error occurred booking the treatment."
                );

                const isSessionExpired =
                    errorResponse?.response?.data?.session_expired;

                if (isSessionExpired) {
                    push.error({
                        title: "Session Expired",
                        message:
                            "Your session has expired, please start your booking again.",
                    });

                    resetStore.all();
                    await systemStore.systemStatus();
                    await systemStore.createSession();
                    router.push({ name: "Index" });

                    return false;
                }

                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: "There was an error booking the treatment.",
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function removeTreatment(
            bookingSpaItem: BookingSpaItem,
            bookingPmsItemId: number | null,
            bookingPmsItemGuestId: number | null,
            spaItemId: number,
            spaFolioId: number,
            spaFolioItemId: number
        ): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/treatment/cancel", {
                    booking_id: bookingStore.state.booking.id,
                    booking_pms_item_id: bookingPmsItemId,
                    booking_pms_item_guest_id: bookingPmsItemGuestId,
                    webfolio_id: systemStore.state.webfolioId,
                    session_id: systemStore.state.sessionId,
                    spa_item_id: spaItemId,
                    spa_folio_id: spaFolioId,
                    spa_folio_item_id: spaFolioItemId,
                });

                bookingStore.state.booking = response.data.data;

                push.success({
                    title: "Booking Update",
                    message: `${
                        bookingSpaItem.treatment.title
                    } cancelled at ${moment(
                        bookingSpaItem.start_time,
                        "YYYY-MM-DDHHmmss"
                    ).format("hh:mm")} on ${moment(
                        bookingSpaItem.start_time,
                        "YYYY-MM-DDHHmmss"
                    ).format("MMM Do")}.`,
                });

                return true;
            } catch (errorResponse: any) {
                logErrorDetails(
                    errorResponse,
                    "An error occurred cancelling the treatment."
                );
                const isSessionExpired =
                    errorResponse?.response?.data?.session_expired;

                if (isSessionExpired) {
                    push.error({
                        title: "Session Expired",
                        message:
                            "Your session has expired, please start your booking again.",
                    });

                    resetStore.all();
                    await systemStore.systemStatus();
                    await systemStore.createSession();
                    router.push({ name: "Index" });

                    return false;
                }

                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: "there was an error removing the treatment.",
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function getTreatmentAvailability(
            spa_item_id: number = 0,
            booking_pms_item_id: number | null = null,
            booking_pms_item_guest_id: number | null = null
        ): Promise<boolean> {
            state.isLoading = true;
            state.selectedTimeslot = null;

            await nextTick();

            try {
                const response = await api.post("api/treatment/availability", {
                    start_date:
                        state.selectedTreatmentDate ??
                        bookingStore.state.booking.start_date,
                    venue_id: bookingStore.state.booking.venue_id,
                    webfolio_id: systemStore.state.webfolioId,
                    session_id: systemStore.state.sessionId,
                    customer_id: systemStore.state.customerId,
                    spa_item_id: spa_item_id,
                    booking_pms_item_id: booking_pms_item_id,
                    booking_pms_item_guest_id: booking_pms_item_guest_id,
                });

                state.treatmentAvailability = response.data.data;

                return true;
            } catch (errorResponse: any) {
                logErrorDetails(
                    errorResponse,
                    "An error occurred fetching treatment availability."
                );
                const isSessionExpired =
                    errorResponse?.response?.data?.session_expired;

                if (isSessionExpired) {
                    push.error({
                        title: "Session Expired",
                        message:
                            "Your session has expired, please start your booking again.",
                    });

                    resetStore.all();
                    await systemStore.systemStatus();
                    await systemStore.createSession();
                    router.push({ name: "Index" });

                    return false;
                }

                state.treatmentAvailability = [];
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message:
                        "There is currently no availability for this treatment.",
                });
                return false;
            } finally {
                const minSpinnerTime = new Promise((resolve) =>
                    setTimeout(resolve, 500)
                );

                await minSpinnerTime;

                state.isLoading = false;
            }
        }

        async function fetchTreatments(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/treatment/list", {
                    booking_id: bookingStore.state.booking.id,
                    category_id: state.selectedCategory,
                    venue_id: bookingStore.state.booking.venue_id,
                    selected_filters: state.selectedFilters,
                });

                state.treatments = response.data.data;

                return true;
            } catch (errorResponse: any) {
                logErrorDetails(
                    errorResponse,
                    "An error occurred fetching treatments."
                );
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message:
                        "No treatments are currently available, please try again later.",
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function fetchTreatmentCategories(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/treatment/categories", {
                    booking_id: bookingStore.state.booking.id,
                    venue_id: bookingStore.state.booking.venue_id,
                    selected_filters: state.selectedFilters,
                });

                state.categories = response.data.data;

                return true;
            } catch (errorResponse: any) {
                logErrorDetails(
                    errorResponse,
                    "Error fetching treatment categories"
                );
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message:
                        "No treatments are currently available, please try again later.",
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function fetchTreatmentFilters(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/treatment/filters", {
                    booking_id: bookingStore.state.booking.id,
                    venue_id: bookingStore.state.booking.venue_id,
                });

                state.filters = response.data.data;

                return true;
            } catch (errorResponse: any) {
                logErrorDetails(
                    errorResponse,
                    "An error occurred fetching filters."
                );
                
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: "There was an error fetching filters.",
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        const setSelectedCategory = (category: number) => {
            state.selectedCategory = category;

            fetchTreatments();
        };

        const setSelectedTreatmentDate = (date: string | number | null) => {
            state.selectedTreatmentDate = date;
        };

        const setSelectedTimeslot = (timeslot: TreatmentAvailability) => {
            state.selectedTimeslot = timeslot;
        };

        const logErrorDetails = (
            errorResponse: any,
            defaultMessage: string
        ) => {
            const errorMessage =
                errorResponse.response?.data?.message || defaultMessage;
            const errorStatus =
                errorResponse.response?.status || "Unknown status";
            const errorData =
                errorResponse.response?.data || "No additional data";

            console.error("Error Message:", errorMessage);
            console.error("Error Status:", errorStatus);
            console.error("Error Data:", errorData);

            Sentry.captureException(new Error(errorMessage), {
                extra: {
                    status: errorStatus,
                    data: errorData,
                },
            });
        };


        const $reset = () => {
            Object.assign(state, initialState());
        };

        return {
            state,
            setSelectedCategory,
            fetchTreatmentCategories,
            fetchTreatmentFilters,
            fetchTreatments,
            bookTreatment,
            removeTreatment,
            getTreatmentAvailability,
            setSelectedTreatmentDate,
            setSelectedTimeslot,
            $reset,
        };
    },
    {
        persist: {
            storage: sessionStorage,
        },
    }
);
