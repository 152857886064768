<template>
    <div
        v-if="background"
        :class="computedClasses"
        :title="altText"
        :style="{ backgroundImage: `url(${loaded ? imageSrc : ''})` }"
        role="img"
        :aria-label="altText"
    >
        <div v-if="!loaded" class="animated-placeholder"></div>
    </div>
    <div v-else class="relative">
        <img
            v-if="loaded"
            :src="imageSrc"
            :alt="altText"
            :title="altText"
            :class="computedClasses"
            loading="lazy"
        />
        <div v-else class="animated-placeholder"></div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, onUnmounted } from "vue";

interface Props {
    src?: string | (() => string);
    alt?: string;
    background?: boolean;
    variant?: "basic" | "cover";
}

const props = defineProps({
    src: { type: [String, Function], default: "" },
    alt: { type: String, default: "Image description" },
    background: { type: Boolean, default: false },
    variant: { type: String, default: "basic" },
});

const loaded = ref(false);
const variant = props.variant || "basic";

const imageSrc = computed(() => {
    return typeof props.src === "function" ? props.src() : props.src;
});

const altText = computed(() => {
    return props.alt || "Image description";
});

const computedClasses = computed(() => {
    const baseClass = "bg-cover bg-center w-full";

    let variantClasses: Record<string, string>;

    variantClasses = {
        basic: `h-auto`,
        cover: `h-full`,
        thumb: `h-64`,
    };

    return `${baseClass} ${variantClasses[variant]}`;
});

let observer: IntersectionObserver;

const observeImage = (el: HTMLElement) => {
    observer = new IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting) {
                loaded.value = true;
                observer.unobserve(el);
            }
        },
        { rootMargin: "50px" }
    );

    if (!loaded.value) {
        observer.observe(el);
    }
};

onMounted(() => {
    if (!loaded.value) {
        loaded.value = true;
    }

    if (!loaded.value) {
        const element = document.querySelector(".relative") as HTMLElement;
        observeImage(element);
    }
});

onUnmounted(() => {
    observer && observer.disconnect();
});
</script>

<style scoped lang="scss">
.animated-placeholder {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2efe8 25%, #f7f4f1 50%, #f2efe8 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}
</style>
