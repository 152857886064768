import { reactive } from "vue";
import { defineStore } from "pinia";
import type { Notification, Error } from "@/types/index";
import api from "@/api/axios";
import { useUserStore } from "@/stores/auth/user";
import { useBookingStore } from "@/stores/booking/booking";
import { useBookingStepsStore } from "@/stores/booking/bookingSteps";

interface SystemState {
    isLoading: boolean;
    systemStatus: boolean;
    sessionToken: string | null;
    sessionId: number | null;
    webfolioId: number | null;
    customerId: number | null;
    remainingUptime: number;
    remainingTimeoutMinutes: number;
    notifications: Notification[];
    checkoutError: Error;
    error: Error;
}

const initialState = (): SystemState => ({
    isLoading: false,
    systemStatus: false,
    sessionToken: null,
    sessionId: null,
    webfolioId: null,
    customerId: 24,
    remainingUptime: 0,
    remainingTimeoutMinutes: 0,
    notifications: [],
    checkoutError: {
        hasError: false,
        message: "",
    },
    error: {
        hasError: false,
        message: "",
    },
});

export const useSystemStore = defineStore(
    "system",
    () => {
        const state = reactive<SystemState>(initialState());

        const userStore = useUserStore();
        const bookingStore = useBookingStore();

        function setSessionToken(token: string): void {
            state.sessionToken = token;
        }

        async function systemStatus(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/system/status", {
                    webfolio_id: state.webfolioId,
                });

                state.systemStatus = response.data.data.system_status;
                state.remainingUptime = response.data.data.remaining_uptime;
                state.remainingTimeoutMinutes =
                    response.data.data.webfolio_remaining_timeout_minutes;
                state.customerId =
                    response.data.data.webfolio_customer_id === 0
                        ? 24
                        : response.data.data.webfolio_customer_id;
                state.notifications = response.data.data.notifications;

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function createSession(): Promise<boolean> {
            state.isLoading = true;

            if (
                state.webfolioId ||
                state.sessionId ||
                state.remainingTimeoutMinutes > 0
            ) {
                return false;
            }

            try {
                const response = await api.post("api/session/create", {
                    webfolio_id: state.webfolioId,
                });

                state.sessionId = response.data.data.session_id;
                state.webfolioId = response.data.data.webfolio_id;
                state.customerId = response.data.data.default_customer_id;

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function updateSession(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/session/update", {
                    booking_id: bookingStore.state.booking.id,
                    session_id: state.sessionId,
                    webfolio_id: state.webfolioId,
                    customer_id: userStore.state.user?.rs_customer_id ?? null,
                    customer_guid: userStore.state.user?.rs_guid ?? null,
                });

                if (response.data.data.conflicting_spa_bookings) {
                    await bookingStore.getBooking();

                    const conflictingSpaBookings =
                        response.data.data.spa_bookings_removed;

                    conflictingSpaBookings.forEach((spaItem: any) => {
                        push.error({
                            title: `Oops, it looks like the slot for your treatment, ${spaItem.treatment} at ${spaItem.start_time}, is no longer available. We’ve removed it from your booking to ensure everything flows smoothly during your visit.`,
                            message: `Please review your booking and select another time for this treatment. Just click below to adjust your selections and continue planning your perfect experience!`,
                        });
                    });

                    const bookingStepsStore = useBookingStepsStore();
                    await bookingStepsStore.resetToStep(2);
                }

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        const initaliseSystem = async () => {
            try {
                await systemStatus();
                if (
                    state.systemStatus &&
                    !state.webfolioId &&
                    !state.sessionId
                ) {
                    await createSession();
                }
            } catch (error) {
                console.error("Error fetching system status", error);
            }
        };

        const $reset = () => {
            Object.assign(state, initialState());
        };

        return {
            state,
            $reset,
            systemStatus,
            createSession,
            updateSession,
            initaliseSystem,
            setSessionToken,
        };
    },
    {
        persist: {
            storage: sessionStorage,
        },
    }
);
