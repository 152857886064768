import { defineStore } from "pinia";
import { ref } from "vue";

export const useThemeStore = defineStore("theme", () => {
    const currentTheme = ref("default");

    const setTheme = (theme: string) => {
        currentTheme.value = theme;
    };

    const $reset = () => {
        currentTheme.value = "default";
    };

    return { currentTheme, setTheme, $reset };
});
