import { reactive } from "vue";
import { defineStore } from "pinia";
import type { Profile, Error } from "@/types";
import api from "@/api/axios";
import * as Sentry from "@sentry/vue";
interface ProfileState {
    isLoading: boolean;
    profile: Profile | null;
    formErrors: FormErrors;
    error: Error;
}

export interface FormErrors {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    password_confirmation: string
    gender: string;
    address: {
        address_1: string;
        address_2: string;
        city: string;
        county: string;
        country: string;
        post_code: string;
    };
    phone: string;
    mobile: string;
    date_of_birth: string;
    marketing_email: string;
    marketing_sms: string;
    marketing_mail: string;
    marketing_call: string;
}

const initialState = (): ProfileState => ({
    isLoading: true,
    profile: null,
    formErrors: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        gender: "",
        address: {
            address_1: "",
            address_2: "",
            city: "",
            county: "",
            country: "",
            post_code: "",
        },
        phone: "",
        mobile: "",
        date_of_birth: "",
        marketing_email: "",
        marketing_sms: "",
        marketing_mail: "",
        marketing_call: "",
    },
    error: {
        hasError: false,
        message: "",
    },
});

export const useProfileStore = defineStore(
    "profile",
    () => {
        const state = reactive<ProfileState>(initialState());

        async function getProfile(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.get("api/account/profile");
                state.profile = response.data.data;

                return true;
            } catch (errorResponse: any) {
                logErrorDetails(
                    errorResponse,
                    "Profile retrieval unsuccessful."
                );

                if (
                    errorResponse.response &&
                    errorResponse.response.status === 401
                ) {
                    console.log(
                        "User is not authenticated. Handling 401 error."
                    );
                    return false;
                } else {
                    state.formErrors = {
                        ...state.formErrors,
                        ...(errorResponse.response?.data?.errors || {}),
                    };
                    
                    state.error.hasError = true;
                    state.error.message =
                        errorResponse.response?.data?.message ||
                        "An error occurred.";
                    return false;
                }
            } finally {
                state.isLoading = false;
            }
        }

        async function updateProfile(): Promise<boolean> {
            state.isLoading = true;

            try {
                await api.post("api/account/profile", state.profile);

                return true;
            } catch (errorResponse: any) {
                logErrorDetails(errorResponse, "Profile update unsuccessful.");

                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        const logErrorDetails = (
            errorResponse: any,
            defaultMessage: string
        ) => {
            const errorMessage =
                errorResponse.response?.data?.message || defaultMessage;
            const errorStatus =
                errorResponse.response?.status || "Unknown status";
            const errorData =
                errorResponse.response?.data || "No additional data";

            console.error("Error Message:", errorMessage);
            console.error("Error Status:", errorStatus);
            console.error("Error Data:", errorData);

            Sentry.captureException(new Error(errorMessage), {
                extra: {
                    status: errorStatus,
                    data: errorData,
                },
            });
        };

        const $reset = () => {
            Object.assign(state, initialState());
        };

        return {
            state,
            getProfile,
            updateProfile,
            $reset,
        };
    },
    {
        persist: {
            storage: sessionStorage,
        },
    }
);
